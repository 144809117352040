<template>
  <span>
    <b-input-group class="mb-3" style="justify-content: flex-start;">
      <b-input-group-addon v-if="hasFilters" slot="prepend">
        <b-button-group class="mr-2">
          <b-dropdown
            ref="filterDropdown"
            left
            :text="filterLabel"
            class="no-rounded-corners"
          >
            <b-dropdown-form :style="dynamicDropdownStyles">
              <div v-if="dateRadioGroupExists">
                <date-range-radio-group
                  ref="dateGroup"
                  :filters="filterOptions.dateRadioGroup.filters"
                  :selection="filterOptions.dateRadioGroup.selection"
                  :set-default="true"
                  @changed="handleDateChange"
                />
              </div>
              <b-dropdown-divider v-if="hasFilters" />
              <b-dropdown-form>
                <div class="mb-1">
                  <b-button v-if="hasFilters" class="dropdown-button" size="sm" variant="info" @click="removeFilters">Reset</b-button>
                </div>
                <div>
                  <b-button v-if="hasFilters" class="dropdown-button" size="sm" variant="success" @click="hideDropdown">Done</b-button>
                </div>
              </b-dropdown-form>
            </b-dropdown-form>
          </b-dropdown>
        </b-button-group>
      </b-input-group-addon>
    </b-input-group>
  </span>
</template>

<script>
import DateRangeRadioGroup from '@/components/shared/DateRangeRadioGroup'
import RadioGroup from '@/components/shared/RadioGroup'
import FeatherIcon from '@/components/shared/FeatherIcon'
import _ from 'lodash'

export default {
  name: 'VirtualPhoneCallLogFilters',
  components: { DateRangeRadioGroup, RadioGroup, FeatherIcon },
  props: {
    filterOptions: Object,
    filterLabel: String,
  },
  data() {
    return {
      debounced: _.debounce(this.emitChange, 1000),
      selectedRadioFilters: {},
      selectedDateFilter: {},
      selectedDateFilterType: '',
    }
  },
  computed: {
    dynamicDropdownStyles() {
      let width = this.filterOptions.dateRadioGroup || this.hasFilter ? 256 : 160
      return `width: ${width}px; padding-bottom:0px;`
    },
    dateRadioGroupExists() {
      return this.filterOptions.dateRadioGroup && (this.filterOptions.dateRadioGroup.filters || this.filterOptions.dateRadioGroup.custom)
    },
    allFilters() {
      let dates = {}
      const filter = this.selectedDateFilter

      if (this.selectedDateFilter && this.filterOptions.dateRadioGroup) {
        if (this.selectedDateFilter.startTime) {
          dates.start_date = filter.startTime
        }
        if (this.selectedDateFilter.endTime) {
          dates.end_date = filter.endTime
        }
      }

      return dates
    },
    hasFilters() {
      return this.filterOptions && this.filterOptions.dateRadioGroup
    },
  },
  methods: {
    hideDropdown() {
      this.$refs.filterDropdown.hide(true)
    },
    handleRadioChange(groupId, selection) {
      this.$set(this.selectedRadioFilters, groupId, selection)
      this.emitChange()
    },
    radioGroupsExist() {
      return this.filterOptions.radioGroups?.length > 0
    },
    handleDateChange(selection) {
      // Replace with a clone so that we always trigger a Vue update.
      this.selectedDateFilter = Object.assign({}, selection)
      this.emitChange()
    },
    removeFilters() {
      if (this.$refs.dateGroup) {
        this.$refs.dateGroup.clearSelection()
      }
    },
    refreshFilters(){
      this.$refs.dateGroup.emitChange()
    },
    emitChange() {
      this.$emit('changed', {
        filters: this.allFilters,
      })
    },
  },

}
</script>

<style scoped>
.dropdown-button{
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
