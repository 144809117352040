var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "hold-list" }, [
    _vm._m(0),
    _c("div", { staticClass: "text-center col-8 on-hold-info" }, [
      _c("div", { staticClass: "call-on-hold mt-8 d-flex flex-row" }, [
        _c(
          "span",
          { staticClass: "on-hold-number", staticStyle: { margin: "auto" } },
          [_vm._v("\n        " + _vm._s(_vm.callOnHoldString) + "\n      ")]
        ),
        _c(
          "div",
          {
            staticClass: "mt-4 float-right d-flex flex-row",
            staticStyle: {
              "align-items": "center",
              "margin-top": ".5rem!important",
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "resume-button hold-list-button",
                attrs: { id: "resume-button" },
                on: {
                  click: function ($event) {
                    return _vm.resumeCallHandler()
                  },
                },
              },
              [
                _c("fa-icon", { staticClass: "mr-3", attrs: { icon: "play" } }),
                _c(
                  "b-tooltip",
                  {
                    attrs: {
                      target: `resume-button`,
                      placement: "top",
                      triggers: "hover focus",
                    },
                  },
                  [_vm._v("\n            Resume call\n          ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "end-button hold-list-button",
                attrs: { id: "end-button" },
                on: {
                  click: function ($event) {
                    return _vm.finishCallHandler()
                  },
                },
              },
              [
                _c("fa-icon", { attrs: { icon: "phone-alt" } }),
                _c(
                  "b-tooltip",
                  {
                    attrs: {
                      target: `end-button`,
                      placement: "top",
                      triggers: "hover focus",
                    },
                  },
                  [_vm._v("\n            Finish call\n          ")]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "on-hold-label col-4" }, [
      _c("span", [_vm._v("On Hold")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }