var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c(
        "b-input-group",
        {
          staticClass: "mb-3",
          staticStyle: { "justify-content": "flex-start" },
        },
        [
          _vm.hasFilters
            ? _c(
                "b-input-group-addon",
                { attrs: { slot: "prepend" }, slot: "prepend" },
                [
                  _c(
                    "b-button-group",
                    { staticClass: "mr-2" },
                    [
                      _c(
                        "b-dropdown",
                        {
                          ref: "filterDropdown",
                          staticClass: "no-rounded-corners",
                          attrs: { left: "", text: _vm.filterLabel },
                        },
                        [
                          _c(
                            "b-dropdown-form",
                            { style: _vm.dynamicDropdownStyles },
                            [
                              _vm.dateRadioGroupExists
                                ? _c(
                                    "div",
                                    [
                                      _c("date-range-radio-group", {
                                        ref: "dateGroup",
                                        attrs: {
                                          filters:
                                            _vm.filterOptions.dateRadioGroup
                                              .filters,
                                          selection:
                                            _vm.filterOptions.dateRadioGroup
                                              .selection,
                                          "set-default": true,
                                        },
                                        on: { changed: _vm.handleDateChange },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.hasFilters
                                ? _c("b-dropdown-divider")
                                : _vm._e(),
                              _c("b-dropdown-form", [
                                _c(
                                  "div",
                                  { staticClass: "mb-1" },
                                  [
                                    _vm.hasFilters
                                      ? _c(
                                          "b-button",
                                          {
                                            staticClass: "dropdown-button",
                                            attrs: {
                                              size: "sm",
                                              variant: "info",
                                            },
                                            on: { click: _vm.removeFilters },
                                          },
                                          [_vm._v("Reset")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _vm.hasFilters
                                      ? _c(
                                          "b-button",
                                          {
                                            staticClass: "dropdown-button",
                                            attrs: {
                                              size: "sm",
                                              variant: "success",
                                            },
                                            on: { click: _vm.hideDropdown },
                                          },
                                          [_vm._v("Done")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }