var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "phones-page" },
    [
      _c("virtual-phone-masthead", {
        attrs: { title: "Calling as" },
        on: { loaded: _vm.loadedPhoneMasthead, changed: _vm.setVirtualPhone },
      }),
      _vm.virtualPhonesLoaded
        ? _c("div", [
            _vm.arePhonesActive
              ? _c(
                  "div",
                  [
                    _c("virtual-phone-call-log-filters", {
                      ref: "callLogFilters",
                      attrs: {
                        "filter-options": _vm.filterOptions,
                        "filter-label": "Filters",
                      },
                      on: { changed: _vm.setFiltersApplied },
                    }),
                    _vm.loaded
                      ? _c(
                          "b-row",
                          {
                            staticClass: "d-flex align-content-start flex-wrap",
                          },
                          [
                            _vm.callLogs.length
                              ? _c(
                                  "div",
                                  { staticClass: "p-0 col-lg-4 log-border" },
                                  [
                                    _c("b-col", { staticClass: "p-0" }, [
                                      _c(
                                        "ul",
                                        {
                                          staticClass: "list-group",
                                          style: {
                                            height: `${_vm.idealListHeight}px`,
                                            overflow: "scroll",
                                          },
                                        },
                                        _vm._l(_vm.callLogs, function (log) {
                                          return _c("call-log-list-item", {
                                            key: log.initial_call_id,
                                            attrs: { "virtual-phone-log": log },
                                          })
                                        }),
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  { staticClass: "list-group no-logs-margin" },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "No call logs found for this number"
                                      ),
                                    ]),
                                  ]
                                ),
                            _c(
                              "div",
                              { staticClass: "order-md-1 col-sm-12 col-md-8" },
                              [
                                _c(
                                  "b-col",
                                  [
                                    _c("dial-pad", {
                                      attrs: {
                                        "destination-number":
                                          _vm.destinationNumber,
                                        "outgoing-call": _vm.outgoingCall,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _c("ct-centered-spinner", {
                          attrs: { height: "260px" },
                        }),
                  ],
                  1
                )
              : _c("div", [
                  _c("p", [_vm._v("There are currently no active phones.")]),
                ]),
          ])
        : _c("ct-centered-spinner", { attrs: { height: "260px" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }