var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.parentClasses,
      attrs: { name: _vm.value },
      on: {
        click: function ($event) {
          return _vm.handleClick()
        },
      },
    },
    [
      _vm._v("\n  " + _vm._s(_vm.value) + "\n  "),
      _vm.subDig
        ? _c("div", { staticClass: "sub-dig" }, [
            _vm._v("\n    " + _vm._s(_vm.subDig) + "\n  "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }