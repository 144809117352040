<template>
  <div>
    <li class="d-flex call-log-item" @mousedown="phoneLogClicked">
      <div class="arrow">
        <fa-icon v-if="callDirectionOutbound" icon="arrow-up" class="fa fa-angle-up arrow-up" />
        <fa-icon v-else icon="arrow-up" class="fa fa-angle-down arrow-down" />
      </div>
      <div class="first-block">
        <div style="width: 100%">
          <div
            v-if="callDirectionOutbound"
            class="second-text mt-2"
          >
            {{ contactNameOrPhoneNumber(people, clientPhoneNumber.substring(2), generateFriendlyNumber(clientPhoneNumber), isNotAccountOwner) }}
          </div>
          <div
            v-else
            class="second-text"
          >
            {{ contactNameOrPhoneNumber(people, clientPhoneNumber.substring(2), generateFriendlyNumber(clientPhoneNumber), isNotAccountOwner) }}
          </div>
          <div class="alternate-date">
            {{ callTimestamp.time }}
          </div>
        </div>
      </div>
      <div class="second-block">
        <div class="event-type">
          <div v-if="virtualPhoneLog.answered">
            {{ completeCallText }}
            <div class="completed-call-indicator" />
          </div>
          <div v-else>
            {{ incompleteCallText }}
            <div class="missed-call-indicator" />
          </div>
        </div>
        <div class="friendly-date">
          {{ callTimestamp.date }}
        </div>
      </div>
    </li>
    <virtual-phone-call-log-edit-selection
      ref="virtual-phone-call-log-edit-selection"
      :contact-or-phone="contactNameOrPhoneNumber(people, clientPhoneNumber.substring(2), generateFriendlyNumber(clientPhoneNumber), isNotAccountOwner)"
      :virtual-phone-log="virtualPhoneLog"
    />
  </div>
</template>

<script>
import { generateFriendlyNumber, formatDateTime, returnDateForBrowserTimezone } from '@/common/modules/formatters'
import { mapGetters } from 'vuex'
import { contactNameOrPhoneNumber } from '@/helpers'
import VirtualPhoneCallLogEditSelection from '@/components/VirtualPhones/VirtualPhoneCallLogEditSelection'

export default {
  name: "CallLogListItem",
  components: { VirtualPhoneCallLogEditSelection },
  props: {
    virtualPhoneLog: {
      type: Object,
      value: {},
    },

  },
  data() {
    return {
      incompleteCallText: 'No answer',
      completeCallText: 'Completed',
      callTimestamp: '',
      callDirectionOutbound: false,
      clientPhoneNumber: '',
    }
  },
  computed: {
    ...mapGetters('account', ['people', 'isNotAccountOwner']),
  },
  beforeMount() {
     this.setCallLogData()
  },
  mounted() {
    this.callTimestamp = this.setCallTimeStamp()
  },
  methods: {
    generateFriendlyNumber: generateFriendlyNumber,
    formatDateTime: formatDateTime,
    returnDateForBrowserTimezone: returnDateForBrowserTimezone,
    capFirstLetter(eventType){
      return eventType.charAt(0).toUpperCase() + eventType.slice(1)
    },
    setCallTimeStamp(){
      return this.formatDateTime(this.returnDateForBrowserTimezone(this.virtualPhoneLog.started_at))
    },
    phoneLogClicked(){
      this.$refs['virtual-phone-call-log-edit-selection'].show()
    },
    setCallLogData(){
      this.callDirectionOutbound = this.virtualPhoneLog.direction === 'outbound'
      this.clientPhoneNumber = this.callDirectionOutbound ? this.virtualPhoneLog.to_number : this.virtualPhoneLog.from_number
    },
    contactNameOrPhoneNumber: contactNameOrPhoneNumber,
  },
}
</script>

<style lang="scss" scoped>

li:hover {
  @include ct-ui-selectable;
  background-color: #e1e9ff;
  opacity: .90;
}

.call-log-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  height: 60px; // todo update to be same height as voicemail rows
  position: relative;
}

.first-block {
  position: relative;
  display: block;
  width: 60%;
  margin-left: 28px;
}

.second-block {
  position: relative;
  display: block;
  width: 40%;
  margin-right: 12px;
}

.image-adjust {
  margin-left: 60px;
}

.second-text {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.third-text {
  bottom: 5px;
  left: 5px;
}

.missed-call-indicator {
  box-sizing: border-box;
  display: inline-block;
  background-color: #F46E36;
  border-radius: 160px;
  height: 14px;
  width: 14px;
}

.completed-call-indicator {
  box-sizing: border-box;
  display: inline-block;
  background-color: #3DE066;
  border-radius: 160px;
  height: 14px;
  width: 14px;
}

.arrow{
  position: absolute;
  width: 20px;
  left: 10px;
  top: 10px;
}

.arrow-down {
  transform: rotate(-135deg);
}

.arrow-up {
  transform: rotate(45deg);
}

.call-log-row {
  justify-content: space-between;
  div:nth-child(1){
    text-align: left;
  }
  div:nth-child(2){
    text-align: right;
  }
}

.callLogInner {
  text-align: right;
}

.callLogUpper {
  text-align: left;
}

.caller {
  line-height: 20px;
  font-size: 16px;
  font-weight: 600;
}

.logItems {
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
}

.inbound-number {
  font-size: 14px;
  padding-left: 19px;
}

.event-type {
  padding-top: 7px;
  top: 15px;
  right: 0;
  text-align: right;
}

.alternate-date {
  font-size: 12px;
  bottom: 5px;
  position: absolute;
  text-align: left;
}

.friendly-date {
  font-size: 12px;
  bottom: 5px;
  right: 0;
  position: absolute;
  text-align: right;
}
</style>
