var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "phoneString" },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.numericDisplay,
            expression: "numericDisplay",
          },
        ],
        staticClass: "my-auto",
        attrs: { type: "text" },
        domProps: { value: _vm.numericDisplay },
        on: {
          keypress: function ($event) {
            return _vm.isAcceptablePhoneChar($event)
          },
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.numericDisplay = $event.target.value
            },
            _vm.updateHandler,
          ],
        },
      }),
      _c("b-icon-backspace-fill", {
        staticClass: "removeDigit",
        on: {
          click: function ($event) {
            return _vm.phoneStringRemove()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }