<template>
  <b-modal
    ref="virtual-phone-call-log-edit-selection"
    size="lg"
    :title="detailsTitle"
    hide-footer
  >
    <div>
      <b-card class="details-card mb-3">
        <div>
          <b>Contact/Number:</b> {{ contactOrPhone }}
        </div>
        <div>
          <b>Date/Time:</b> {{ formatDateTime(virtualPhoneLog.started_at).date }} at {{ formatDateTime(virtualPhoneLog.started_at).time }}
        </div>
        <div>
          <b>Call Type:</b> {{ capFirstLetter(virtualPhoneLog.direction) }}
        </div>
        <div v-if="virtualPhoneLog.event_type === 'completed'">
          <b>Status:</b> {{ capFirstLetter(virtualPhoneLog.event_type) }}
        </div>
        <div v-else>
          <b>Status:</b> No answer
        </div>
      </b-card>
      <div class="mb-2">
        <hr>
      </div>
      <b-container class="contact-list m-0">
        <b-card-group class="mb-3">
          <b-card class="pb-0 contact-card" :disabled="isUnknownNumber" @click="changeContact">
            Set Contact Information
          </b-card>
          <b-card class="pb-0 contact-card" :disabled="isUnknownNumber" @click="sendToSms">
            Send SMS Message
          </b-card>
          <b-card class="pb-0 contact-card" :disabled="isUnknownNumber" @click="callBack">
            Call Back
          </b-card>
        </b-card-group>
        <div class="d-flex flex-row m-auto btn-spacing">
          <b-button v-if="isNumberBlocked" variant="primary" :disabled="isUnknownNumber" @click="unblockButtonClicked">
            Unblock
          </b-button>
          <b-button v-else variant="danger" :disabled="isUnknownNumber" @click="blockButtonClicked">
            Block
          </b-button>
          <b-button variant="danger" @click="deleteCallLog">
            Delete
          </b-button>
        </div>
      </b-container>
      <contact-modal ref="contact-modal" :title="title" :type="modalType" :phone-number="phoneNumber" />
    </div>
  </b-modal>
</template>

<script>
import { formatDateTime, capFirstLetter, formatPhoneToCountryCodeStyleWithout1, formatRemovingCountryCode } from "@/common/modules/formatters"
import ContactModal from '@/components/ContactModal'
import { mapGetters, mapActions } from "vuex"
import { phoneNumberNotKnown } from '@/helpers'

export default {
  name: "VirtualPhoneCallLogEditSelection",
  components: { ContactModal },
  props: {
    contactOrPhone: String,
    virtualPhoneLog: Object,
  },
  data(){
    return {
      modalType: '',
      title: '',
      person: '',
      phoneNumber: '',
    }
  },
  computed: {
    ...mapGetters('account', ['people']),
    ...mapGetters('chatRooms', ['currentChatRoom']),
    ...mapGetters('virtualPhones', ['blockedNumbers']),
    isUnknownNumber() {
      return phoneNumberNotKnown(this.virtualPhoneLog.from_number)
    },
    isNumberBlocked() {
      return this.blockedNumbers.find(number => number.blocked_number === this.phoneNumberDependingOnDirection) != null
    },
    phoneNumberDependingOnDirection() {
      if (this.virtualPhoneLog.direction === 'outbound') {
        return this.virtualPhoneLog.to_number
      }

      return this.virtualPhoneLog.from_number
    },
    detailsTitle(){
      return `Call Log Details for ${this.contactOrPhone}`
    },
  },
  methods: {
    ...mapActions('chatRooms', ['setCurrentChatRoom']),
    ...mapActions('virtualPhones', ['blockNumber', 'unblockNumber', 'getBlockedNumbers', 'deleteCallLog']),
    formatDateTime: formatDateTime,
    capFirstLetter: capFirstLetter,
    formatPhoneToCountryCodeStyleWithout1: formatPhoneToCountryCodeStyleWithout1,
    formatRemovingCountryCode: formatRemovingCountryCode,
    async deleteCallLog() {
       try {
         await this.$store.dispatch('virtualPhones/deleteCallLog', this.virtualPhoneLog.initial_call_id)
       } catch (error) {
         this.$bvToast.toast('A problem occurred when attempting to delete this call log.', {
           title: 'Error',
           variant: 'danger',
           solid: true,
         })
       }
       this.hide()
    },
    show() {
      this.$refs['virtual-phone-call-log-edit-selection'].show()
    },
    hide() {
      this.$refs['virtual-phone-call-log-edit-selection']?.hide()
    },
    findPerson() {
      let person = this.people.find(p => p.person_phones.find(p => p.phone_number === this.formatPhoneToCountryCodeStyleWithout1(this.contactOrPhone)))
      if (typeof person !== 'undefined') {
        this.person = person
      } else {
        this.findPersonByName()
      }
    },
    findPersonByName() {
      let person = this.people.find(p => `${p.first_name} ${p.last_name}` === this.contactOrPhone)
      if (typeof person !== 'undefined') {
        this.person = person
      }
    },
    changeContact() {
      if (this.isUnknownNumber) return

      this.findPerson()

      if (this.person) {
        this.title = 'Edit a contact'
        this.modalType = 'edit'
        this.$refs['contact-modal'].show(this.person)
      } else {
        this.title = 'Add a contact'
        this.modalType = 'add'
        this.phoneNumber = this.contactOrPhone
        this.$refs['contact-modal'].show(this.person)
      }
    },
    async sendToSms() {
      if (this.isUnknownNumber) return

      await this.setCurrentChatRoom({ phoneNumber: this.phoneNumberDependingOnDirection, virtualPhoneId: this.virtualPhoneLog.virtual_phone_id })
      const chatRoomId = this.currentChatRoom?.id
      await this.$router.push({ name: 'sms-chat', params: { currentChatRoomId: chatRoomId } })
    },
    callBack() {
      if (this.isUnknownNumber) return

      const phoneNumber = this.phoneNumberDependingOnDirection

      this.$router.push({ name: 'call',
        params: {
          virtualPhoneId: this.virtualPhoneLog.virtual_phone_id,
          destinationNumber: phoneNumber,
          outgoingCall: true,
        },
      })
      this.hide()
    },
    async blockButtonClicked() {
      if (this.isUnknownNumber) return

      const phoneNumber = this.phoneNumberDependingOnDirection

      try {
        await this.blockNumber(phoneNumber)
      } catch {
        this.$bvToast.toast('A problem occurred blocking number', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }

      this.hide()
    },
    async unblockButtonClicked() {
      if (this.isUnknownNumber) return

      const blockedNumberId = this.blockedNumbers.find(number => number.blocked_number === this.phoneNumberDependingOnDirection).id

      try {
        await this.unblockNumber(blockedNumberId)
      } catch {
        this.$bvToast.toast('An problem occurred unblocking number', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }

      this.hide()
    },
  },
}
</script>

<style lang="scss" scoped>

.details-card {
  @include ct-ui-card-shadow;
  margin: 10px;
  border-radius: 15px;
  min-height: 100px;
  max-width: 738px;
  flex-grow: 1;
  flex-shrink: 0;
}
.btn-spacing > button{
  margin: 0 2.5px
}

.contact-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .contact-card {
    @include ct-ui-card-shadow;

    min-height: 100px;
    flex-grow: 1;
    flex-shrink: 0;
    min-width: 220px;
    max-width: 400px;
    margin: 10px;
    border-radius: 15px;

    &:not([disabled]) {
      @include ct-ui-selectable;
    }

    &[disabled] {
      cursor: not-allowed;
      border: $input-disabled-bg;
      color: $text-muted;
    }
  }
}
</style>
