<template>
  <div class="callDisplay">
    <span class="displayNumber">{{ value }}</span>
    <stopwatch
      class="displayTimeCounter"
      :bus="bus"
    />
  </div>
</template>

<script>

import Stopwatch from "@/components/DialPad/Stopwatch"

export default {
  name: 'CallDisplay',
  components: { Stopwatch },
  props: {
    value: {
      type: String,
      default: '',
      required: true,
    },
    bus: {
      type: Object,
    },
  },
}
</script>

<style lang="scss" scoped>
  $lightGray: #2D2D2D;

  .pad {
    .callDisplay {
      width: 100%;
      height: 80px;
      border-bottom: 1px solid #858585;
      display: flex;
      flex-direction: column;
      padding-bottom: 0.25rem;
    }
    .displayNumber {
      width: 68%;
      margin-left: 16%;
      height: 60px;
      font-size: 30px;
      color: $lightGray;
      font-weight: 700;
      letter-spacing: 2px;
      margin-top: 12px;
      border: 1px solid white;
      overflow: hidden;
      text-align: center;
    }
  }

</style>
