<template>
  <div
    :class="parentClasses"
    :name="value"
    @click="handleClick()"
  >
    {{ value }}
    <div v-if="subDig" class="sub-dig">
      {{ subDig }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'DigitNumber',
  props: {
    classes: {
      type: String,
    },
    subDig: {
      type: String,
    },
    value: {
      type: String,
      required: true,
    },
    clickHandler: {
      type: Function,
    },
  },
  data(){
    return{
      clicked: false,
    }
  },
  computed: {
    parentClasses(){
      let initialClasses = 'dig number-dig '
      if(this.classes){
        initialClasses = initialClasses.concat(this.classes)
      }
      if(this.clicked){
        initialClasses = initialClasses.concat(' clicked')
      }
      return initialClasses
    },
  },
  methods: {
    handleClick: function() {
      this.clicked = true
      this.$emit('clickHandler', this.value)
      setTimeout(()=> {this.clicked = false},300)
    },
  },
}
</script>


<style lang="scss" scoped>
  $lightGray: #a0a0a0;
  $pulseDuration: 0.3s;

  @keyframes pulse-gray {
    0% {
      box-shadow: inset 0 0 0px 15px $lightGray, inset 0 0 0px 15px white;
      -ms-transform: scale(1,1);
      -webkit-transform: scale(1,1);
      transform: scale(1,1);
    }
    10%{
      -ms-transform: scale(0.8,0.8);
      -webkit-transform: scale(0.8,0.8);
      transform: scale(0.8,0.8);
    }
    30% {
      box-shadow: inset 0 0 0px 5px $lightGray, inset 0 0 0px 15px white;
    }
    60% {
      box-shadow: inset 0 0 0px 0px $lightGray, inset 0 0 0px 0px white;
      -ms-transform: scale(0.8,0.8);
      -webkit-transform: scale(0.8,0.8);
      transform: scale(0.8,0.8);
    }
    100% {
      -ms-transform: scale(1,1);
      -webkit-transform: scale(1,1);
      transform: scale(1,1);
    }
  }
  .pad {
    .dial-pad {
      .digits {
        .dig-spacer {
          width: 60px;
          margin: 10px calc(50% - 90px);
          float: left;
        }
        .dig {
          &.clicked {
            animation: pulse-gray linear $pulseDuration 1;
          }
          &:nth-child(3n-1) {
            margin: 10px calc(50% - 90px) !important;
          }
          &.asterisk{
            padding-top: 17px;
            height: 43px;
          }
          &.pound{
            padding-top: 10px;
            height: 50px;
          }
          .sub-dig {
            font-size: 8px;
            font-weight: 600;
            position: relative;
            top: -2px;
          }
          &.addPerson, &.goBack {
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 55% auto;
            margin-bottom: 25px;
            box-shadow: 0px  25px 30px -15px black;
          }
        }
      }
    }
  }

</style>
