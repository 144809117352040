<template>
  <div class="phoneString">
    <input
      v-model="numericDisplay"
      class="my-auto"
      type="text"
      @keypress="isAcceptablePhoneChar($event)"
      @input="updateHandler"
    >
    <b-icon-backspace-fill class="removeDigit" @click="phoneStringRemove()" />
  </div>
</template>

<script>

import { BIconBackspaceFill } from 'bootstrap-vue'

export default {
  name: 'PhoneString',
  components: { BIconBackspaceFill },
  props: {
    value: {
      type: String,
      default: '',
      required: true,
    },
  },
  data(){
    return{
      numericDisplay: '',
      clicked: false,
    }
  },
  watch: {
    value(val) {
      this.numericDisplay = val
    },
  },
  methods: {
    phoneStringRemove(){
      this.$emit('removeHandler')
    },
    isAcceptablePhoneChar(e) {
      !e.key.match(/[\d#*]/g) ? e.preventDefault() : true
    },
    updateHandler() {
      this.$emit('updated', this.numericDisplay)
    },
  },
}
</script>


<style lang="scss" scoped>
  $lightGray: #2D2D2D;
  $pulseDuration: 0.3s;

  @keyframes pulse-backspace {
    0% {
      -ms-transform: scale(1,1);
      -webkit-transform: scale(1,1);
      transform: scale(1,1);
    }
    10%{
      -ms-transform: scale(0.8,0.8);
      -webkit-transform: scale(0.8,0.8);
      transform: scale(0.8,0.8);
    }
    60% {
      -ms-transform: scale(0.8,0.8);
      -webkit-transform: scale(0.8,0.8);
      transform: scale(0.8,0.8);
    }
    100% {
      -ms-transform: scale(1,1);
      -webkit-transform: scale(1,1);
      transform: scale(1,1);
    }
  }

  .pad {
    .phoneString{
      width: 100%;
      height: 80px;
      border-bottom: 1px solid #858585;
      display: flex;
      padding-bottom: 0.25rem;
      input{
        background-color: transparent;
        width: 68%;
        margin-left: 16%;
        height: 60px;
        border: none;
        font-size: 28px;
        color: $lightGray;
        font-weight: 700;
        letter-spacing: 2px;
        display: flex;
        text-align: center;
        &:focus{
          outline:none;
        }
      }
      .removeDigit{
        height: 80px;
        font-size: 20px;
        vertical-align: center;
        &:hover {
          cursor: pointer;
        }
        &:active {
          animation: pulse-backspace $pulseDuration 1;
        }
      }
    }
  }


</style>
