var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "li",
        {
          staticClass: "d-flex call-log-item",
          on: { mousedown: _vm.phoneLogClicked },
        },
        [
          _c(
            "div",
            { staticClass: "arrow" },
            [
              _vm.callDirectionOutbound
                ? _c("fa-icon", {
                    staticClass: "fa fa-angle-up arrow-up",
                    attrs: { icon: "arrow-up" },
                  })
                : _c("fa-icon", {
                    staticClass: "fa fa-angle-down arrow-down",
                    attrs: { icon: "arrow-up" },
                  }),
            ],
            1
          ),
          _c("div", { staticClass: "first-block" }, [
            _c("div", { staticStyle: { width: "100%" } }, [
              _vm.callDirectionOutbound
                ? _c("div", { staticClass: "second-text mt-2" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.contactNameOrPhoneNumber(
                            _vm.people,
                            _vm.clientPhoneNumber.substring(2),
                            _vm.generateFriendlyNumber(_vm.clientPhoneNumber),
                            _vm.isNotAccountOwner
                          )
                        ) +
                        "\n        "
                    ),
                  ])
                : _c("div", { staticClass: "second-text" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.contactNameOrPhoneNumber(
                            _vm.people,
                            _vm.clientPhoneNumber.substring(2),
                            _vm.generateFriendlyNumber(_vm.clientPhoneNumber),
                            _vm.isNotAccountOwner
                          )
                        ) +
                        "\n        "
                    ),
                  ]),
              _c("div", { staticClass: "alternate-date" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.callTimestamp.time) + "\n        "
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "second-block" }, [
            _c("div", { staticClass: "event-type" }, [
              _vm.virtualPhoneLog.answered
                ? _c("div", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.completeCallText) +
                        "\n          "
                    ),
                    _c("div", { staticClass: "completed-call-indicator" }),
                  ])
                : _c("div", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.incompleteCallText) +
                        "\n          "
                    ),
                    _c("div", { staticClass: "missed-call-indicator" }),
                  ]),
            ]),
            _c("div", { staticClass: "friendly-date" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.callTimestamp.date) + "\n      "
              ),
            ]),
          ]),
        ]
      ),
      _c("virtual-phone-call-log-edit-selection", {
        ref: "virtual-phone-call-log-edit-selection",
        attrs: {
          "contact-or-phone": _vm.contactNameOrPhoneNumber(
            _vm.people,
            _vm.clientPhoneNumber.substring(2),
            _vm.generateFriendlyNumber(_vm.clientPhoneNumber),
            _vm.isNotAccountOwner
          ),
          "virtual-phone-log": _vm.virtualPhoneLog,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }