<template>
  <div class="hold-list">
    <div class="on-hold-label col-4">
      <span>On Hold</span>
    </div>
    <div class="text-center col-8 on-hold-info">
      <div class="call-on-hold mt-8 d-flex flex-row">
        <span class="on-hold-number" style="margin: auto">
          {{ callOnHoldString }}
        </span>
        <div class="mt-4 float-right d-flex flex-row" style="align-items: center;margin-top: .5rem!important;">
          <div id="resume-button" class="resume-button hold-list-button" @click="resumeCallHandler()">
            <fa-icon class="mr-3" icon="play" />
            <b-tooltip :target="`resume-button`" placement="top" :triggers="'hover focus'">
              Resume call
            </b-tooltip>
          </div>
          <div id="end-button" class="end-button hold-list-button" @click="finishCallHandler()">
            <fa-icon icon="phone-alt" />
            <b-tooltip :target="`end-button`" placement="top" :triggers="'hover focus'">
              Finish call
            </b-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HoldList',
  props: {
    resumeCallHandler: {
      type: Function,
      required: true,
    },
    finishCallHandler: {
      type: Function,
      required: true,
    },
    callOnHoldString: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>

$lightGray: #525c66;
$gray: #2D2D2D;
$blue: #285EFA;
$green: #3DE066;
$red: #FA4A5D;

$pulseDuration: 0.5s;

.hold-list{
  display: flex;
  align-items: stretch;
  flex-direction: row;
  border-top: thin solid;
  border-color: $lightGray;
  margin-top: 85px;
}
.on-hold-label {
  display: flex;
  align-items: center;
  background-color: $green;
  border-right: thin solid;
  border-color: $lightGray;
  color: white;
  font-size: .9em;
  height: 50px;
}
.hold-list-button{
  height: 40px;
  width: 40px;
  svg {
    width: 40px;
    margin-top: 12px;
  }
}
.on-hold-number {
  font-size: 1.2em;
  align-items: center;
}
.on-hold-time {
  color: $lightGray;
  font-size: .9em;
}
.on-hold-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
}
.holdButton{
  background-color: $primary;
  padding-top: 11px;
  height: 51px;
  color: white;
  &.clicked{
    animation: pulse-red linear $pulseDuration 1;
  }
}
.call-on-hold {
  margin: auto;
  justify-content: space-evenly;
  width: 100%;

  .resume-button {
    margin: auto;
    color: $green;
    &:hover{
      cursor: pointer;
    }
  }

  .end-button {
    margin: auto;
    color: $red;
    &:hover{
      cursor: pointer;
    }
  }
}

</style>
