<template>
  <span class="displayTimeCounter">{{ formattedElapsedTime }}</span>
</template>

<script>

import { setInterval, clearInterval } from 'timers'

export default {
  name: 'Stopwatch',
  props: ['bus'],
  data() {
    return{
      timer: null,
      loaded: false,
      elapsedTime: 0,
    }
  },
  computed: {
    formattedElapsedTime() {
      const date = new Date(null)
      date.setSeconds(this.elapsedTime / 1000)
      const utc = date.toUTCString()
      return utc.substr(utc.indexOf(":") + 1, 5)
    },
  },
  mounted() {
    this.loaded = true
    this.bus.$on('startTimer', this.startTimer)
    this.bus.$on('finishTimer', this.stopTimer)

  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        this.elapsedTime += 1000
      }, 1000)
    },
    stopTimer() {
      this.elapsedTime = 0
      clearInterval(this.timer)
    },
  },
}
</script>

<style lang="scss" scoped>
  .pad{
    .displayTimeCounter {
      width: 40%;
      margin-left: 30%;
      color: #6c757d;
      font-weight: 600;
      text-align: center;
    }
  }
</style>
