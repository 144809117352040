var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pad" },
    [
      _c(
        "div",
        { staticClass: "dial-pad" },
        [
          !_vm.activeCall
            ? _c("phone-string", {
                attrs: { value: _vm.phoneString },
                on: {
                  removeHandler: _vm.phoneStringSubtract,
                  updated: _vm.phoneStringReplace,
                },
              })
            : _c("call-display", {
                attrs: { value: _vm.phoneString, bus: _vm.bus },
              }),
          _vm.callStatus
            ? _c("div", { staticClass: "call-status" }, [
                _vm._v("\n      " + _vm._s(_vm.callStatus) + "\n    "),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "digits" },
            _vm._l(3, function (col, colIndex) {
              return _c(
                "b-row",
                { key: colIndex },
                _vm._l(_vm.digits, function (row, rowIndex) {
                  return _c(
                    "b-col",
                    { key: rowIndex, attrs: { cols: "4" } },
                    [
                      _c("digit-number", {
                        key: row[colIndex].name,
                        attrs: {
                          value: row[colIndex].name,
                          "sub-dig": row[colIndex].subDig,
                          classes: row[colIndex].classes,
                        },
                        on: {
                          clickHandler: function ($event) {
                            return _vm.phoneStringAdd($event)
                          },
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              )
            }),
            1
          ),
          _c("div", { staticClass: "digits action-digits" }, [
            !_vm.activeCall
              ? _c(
                  "div",
                  {
                    staticClass: "phone-actions",
                    class: _vm.validPhoneNumber ? "" : "callButtonDisabled",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "dig callButton action-dig",
                        on: {
                          click: function ($event) {
                            return _vm.startOutboundCall()
                          },
                        },
                      },
                      [_c("fa-icon", { attrs: { icon: "phone-alt" } })],
                      1
                    ),
                    _c("span", [_vm._v("Call")]),
                  ]
                )
              : _vm._e(),
            _vm.activeCall
              ? _c("div", { staticClass: "phone-actions" }, [
                  _c(
                    "div",
                    {
                      staticClass: "dig cancelCall action-dig",
                      on: {
                        click: function ($event) {
                          return _vm.finishActiveCall()
                        },
                      },
                    },
                    [_c("fa-icon", { attrs: { icon: "phone-alt" } })],
                    1
                  ),
                  _c("span", [_vm._v("End")]),
                ])
              : _vm._e(),
            _vm.activeCall && !_vm.callOnHoldExists && !_vm.isEmergencyCall
              ? _c("div", { staticClass: "phone-actions" }, [
                  _c(
                    "div",
                    {
                      staticClass: "dig holdButton action-dig",
                      on: {
                        click: function ($event) {
                          return _vm.holdCurrentCall()
                        },
                      },
                    },
                    [_c("fa-icon", { attrs: { icon: "pause" } })],
                    1
                  ),
                  _c("span", [_vm._v("Hold")]),
                ])
              : _vm._e(),
            _vm.activeCall && !_vm.callMuted && !_vm.isEmergencyCall
              ? _c("div", { staticClass: "phone-actions" }, [
                  _c(
                    "div",
                    {
                      staticClass: "dig muteButton action-dig",
                      on: {
                        click: function ($event) {
                          return _vm.muteCurrentCall()
                        },
                      },
                    },
                    [_c("fa-icon", { attrs: { icon: "microphone" } })],
                    1
                  ),
                  _c("span", [_vm._v("Mute")]),
                ])
              : _vm._e(),
            _vm.activeCall && _vm.callMuted
              ? _c("div", { staticClass: "phone-actions" }, [
                  _c(
                    "div",
                    {
                      staticClass: "dig unmuteButton action-dig",
                      on: {
                        click: function ($event) {
                          return _vm.unmuteCurrentCall()
                        },
                      },
                    },
                    [_c("fa-icon", { attrs: { icon: "microphone-slash" } })],
                    1
                  ),
                  _c("span", [_vm._v("Unmute")]),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm.callOnHoldExists
        ? _c("hold-list", {
            attrs: {
              "call-on-hold-string": _vm.callOnHold.phoneString,
              "resume-call-handler": _vm.resumeCall,
              "finish-call-handler": _vm.endCallOnHold,
            },
          })
        : _vm._e(),
      _vm.rtcToken != null
        ? _c("video", {
            ref: "videoDev",
            attrs: { playsInline: "", autoPlay: "" },
          })
        : _vm._e(),
      _c("audio", {
        ref: "outboundCallAudio",
        attrs: { src: "/outbound_call_ringing.mp3", loop: "" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }