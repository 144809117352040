<template>
  <div class="pad">
    <div class="dial-pad">
      <phone-string
        v-if="!activeCall"
        :value="phoneString"
        @removeHandler="phoneStringSubtract"
        @updated="phoneStringReplace"
      />
      <call-display
        v-else
        :value="phoneString"
        :bus="bus"
      />
      <div v-if="callStatus" class="call-status">
        {{ callStatus }}
      </div>
      <div class="digits">
        <b-row v-for="(col, colIndex) in 3" :key="colIndex">
          <b-col v-for="(row, rowIndex) in digits" :key="rowIndex" cols="4">
            <digit-number
              :key="row[colIndex].name"
              :value="row[colIndex].name"
              :sub-dig="row[colIndex].subDig"
              :classes="row[colIndex].classes"
              @clickHandler="phoneStringAdd($event)"
            />
          </b-col>
        </b-row>
      </div>
      <div class="digits action-digits">
        <div v-if="!activeCall" class="phone-actions" :class="validPhoneNumber ? '' : 'callButtonDisabled'">
          <div class="dig callButton action-dig" @click="startOutboundCall()">
            <fa-icon icon="phone-alt" />
          </div>
          <span>Call</span>
        </div>
        <div v-if="activeCall" class="phone-actions">
          <div class="dig cancelCall action-dig" @click="finishActiveCall()">
            <fa-icon icon="phone-alt" />
          </div>
          <span>End</span>
        </div>
        <div v-if="activeCall && !callOnHoldExists && !isEmergencyCall" class="phone-actions">
          <div class="dig holdButton action-dig" @click="holdCurrentCall()">
            <fa-icon icon="pause" />
          </div>
          <span>Hold</span>
        </div>
        <div v-if="activeCall && !callMuted && !isEmergencyCall" class="phone-actions">
          <div class="dig muteButton action-dig" @click="muteCurrentCall()">
            <fa-icon icon="microphone" />
          </div>
          <span>Mute</span>
        </div>
        <div v-if="activeCall && callMuted" class="phone-actions">
          <div class="dig unmuteButton action-dig" @click="unmuteCurrentCall()">
            <fa-icon icon="microphone-slash" />
          </div>
          <span>Unmute</span>
        </div>
      </div>
    </div>
    <hold-list
      v-if="callOnHoldExists"
      :call-on-hold-string="callOnHold.phoneString"
      :resume-call-handler="resumeCall"
      :finish-call-handler="endCallOnHold"
    />
    <video
      v-if="rtcToken != null"
      ref="videoDev"
      playsInline
      autoPlay
    />
    <audio ref="outboundCallAudio" src="/outbound_call_ringing.mp3" loop />
  </div>
</template>

<script>
import DigitNumber from "@/components/DialPad/DigitNumber"
import HoldList from "@/components/DialPad/HoldList"
import PhoneString from "@/components/DialPad/PhoneString"
import CallDisplay from "@/components/DialPad/CallDisplay"
import BandwidthRtc from "@bandwidth/webrtc-browser"
import { AsYouType, findPhoneNumbersInText, isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js'
import Vue from 'vue'
import store from "@/store"
import { mapGetters, mapActions } from 'vuex'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

export default {
  name: 'DialPad',
  components: { DigitNumber, PhoneString, CallDisplay, HoldList },
  props: {
    incomingCall: {
      type: Boolean,
      default: false,
    },
    outgoingCall: {
      type: Boolean,
      default: false,
    },
    destinationNumber: null,
  },
  data() {
    return {
      bus: new Vue(),
      phoneString: '',
      validPhoneNumber: false,
      activeCall: false,
      callStatus: '',
      virtualPhoneId: null,
      bandwidthRtc: new BandwidthRtc(),
      activeCallStreamId: null,
      callMuted: false,
      e911CallerId: null,
      e911Geolocation: null,
    }
  },
  setup(props, { root }) {
    if (typeof root === 'undefined') {
      return {}
    }

    root.$store = store

    const {
      useGetters: useSipGetters,
      useMutations: useSipMutations,
    } = createNamespacedHelpers(root.$store, 'sip')

    const { dialCall, dialBackspace, dialPress, registerExtension, terminateCall, setExtraHeaders, setSessionPassword } = useSipMutations([
      'dialCall',
      'dialBackspace',
      'dialPress',
      'registerExtension',
      'terminateCall',
      'setExtraHeaders',
      'setSessionPassword',
    ])

    const { isSessionActive, getDialerDialed } = useSipGetters([
      'isSessionActive',
      'getDialerDialed',
    ])

    return {
      dialCall,
      dialBackspace,
      dialPress,
      registerExtension,
      terminateCall,
      setExtraHeaders,
      setSessionPassword,
      isSessionActive,
      getDialerDialed,
    }
  },
  computed: {
    ...mapGetters('virtualPhones', ['selectedPhone', 'asteriskAuth', 'e911Identifier', 'e911LocationId']),
    ...mapGetters('calls', ['incomingCallId', 'isCallAccepted', 'callId', 'callOnHold', 'callRtcStream']),
    ...mapGetters('bandwidthWebRtc', ['rtcToken', 'participantId']),
    digits(){
      return [
        [
          { name: '1', classes: 'pound' },
          { name: '2', subDig: 'ABC' },
          { name: '3', subDig: 'DEF' },
        ],
        [
          { name: '4', subDig: 'GHI' },
          { name: '5', subDig: 'JKL' },
          { name: '6', subDig: 'MNO' },
        ],
        [
          { name: '7', subDig: 'PQRS' },
          { name: '8', subDig: 'TUV' },
          { name: '9', subDig: 'WXYZ' },
        ],
        [
          { name: '*', classes: 'asterisk' },
          { name: '0', classes: 'pound' },
          { name: '#', classes: 'pound' },
        ],
      ]
    },
    callOnHoldExists() {
      return Object.keys(this.callOnHold).length > 0
    },
    emergencyNumbers() {
      return ['911', '933']
    },
    isEmergencyCall() {
      return this.emergencyNumbers.includes(String(this.getDialerDialed))
    },
  },
  watch: {
    isCallAccepted: {
      async handler(value){
        if(value) {
          await this.startIncomingCall()
          this.setIsCallAccepted(false)
        }
      },
    },
    isSessionActive: {
      async handler(value){
        if(value === null) {
          await this.finishEmergencyCall()
        }
      },
    },
    selectedPhone: {
      async handler(value){
        if(value) {
          this.registerE911()
        }
      },
    },
    destinationNumber(number) {
      this.formatPhoneString(number)
    },
    outgoingCall(){
      this.startOutboundCall()
    },
  },
  async mounted() {
    if (this.isCallAccepted) {
      await this.startIncomingCall()
      this.setIsCallAccepted(false)
    }
    if (this.destinationNumber) {
      this.formatPhoneString(this.destinationNumber)
    }
    if (this.outgoingCall) {
      await this.startOutboundCall()
    }

    this.registerE911()
  },
  methods: {
    ...mapActions('virtualPhones', ['fetchE911Config', 'fetchE911Info']),
    ...mapActions('bandwidthWebRtc', ['setWebRtcToken']),
    ...mapActions('calls', ['holdCall', 'setCallRtcStream', 'hangupCallOnHold', 'setCallOnHold', 'resumeCallOnHold', 'setActiveCall', 'hangup', 'setIsCallAccepted', 'hangupActiveCall']),
    async registerE911() {
      await this.fetchE911Config(this.selectedPhone?.id)
      if (this.asteriskAuth !== null) {
        this.setSessionPassword(this.asteriskAuth)
        const parsedNumber = parsePhoneNumber(this.selectedPhone.number)
        this.registerExtension(parsedNumber.nationalNumber)

        await this.fetchE911Info(this.selectedPhone?.id)
        this.e911CallerId = this.e911Identifier
        this.e911Geolocation = this.e911LocationId
      }
    },
    phoneStringAdd(digit) {
      this.dialPress(digit)
      const number = this.phoneString.concat(digit)
      this.formatPhoneString(number)

      if(this.activeCall) {
        this.bandwidthRtc.sendDtmf(digit)
      }
    },
    formatPhoneString(number) {
      if (number && number.length > 0) {
        number= number.replace(/\D/g, '')
        this.validatePhoneNumber(number)
        if (this.emergencyNumbers.includes(number)) {
          this.phoneString = number
        } else {
          this.phoneString = new AsYouType('US').input(number)
        }
      }
    },
    validatePhoneNumber(number) {
      const num = number.replace(/\D/g, '')
      this.validPhoneNumber = this.emergencyCallAvailable(number) || isValidPhoneNumber(num, 'US')
    },
    emergencyCallAvailable(number) {
      return this.e911CallerId && this.e911Geolocation && this.emergencyNumbers.includes(number)
    },
    setPhoneStringForCall(number) {
      if (this.emergencyNumbers.includes(number)) {
        return number
      }
      const phoneNumber = findPhoneNumbersInText(number, 'US')

      return phoneNumber[0].number.nationalNumber
    },
    phoneStringReplace(string) {
      const number = string.replace(/^(\+)|\D/g, "$1")
      const old = this.phoneString.replace(/^(\+)|\D/g, "$1")
      if (number.length < old.length) {
        this.dialBackspace()
      } else if (number.length > old.length) {
        this.dialPress(number.charAt(number.length - 1))
      }
      this.phoneString = string
      this.formatPhoneString(this.phoneString)
    },
    phoneStringSubtract() {
      this.dialBackspace()
      let num = this.phoneString.replace(/\D/g, '')
      this.phoneString = num.slice(0, -1)
      this.formatPhoneString(this.phoneString)
    },
    setPhoneString() {
      this.phoneString = this.destinationNumber
    },
    async startIncomingCall() {
      this.setActiveCall(this.incomingCallId)
      this.stateActive()
      this.setBandwidthListeners()
      await this.setBandwidthConnection()
    },
    async startOutboundCall() {
      if(this.validPhoneNumber) {
        this.stateActive()
        if (this.isEmergencyCall) {
          this.setExtraHeaders([
            `X-CallerId: ${this.e911CallerId}`,
            `X-Geolocation: <https://emergency.bandwidth.com/locations/5008467/${this.e911Geolocation}>`,
          ])
          this.dialCall()
          this.$store.dispatch('calls/logCall', {
            virtualPhoneId: this.selectedPhone?.id,
            direction: 'outbound',
            toNumber: String(this.getDialerDialed),
            fromNumber: this.selectedPhone?.number,
            eventType: 'emergency',
          })
        } else {
          this.callStatus = 'Connecting'
          await this.$store.dispatch('bandwidthWebRtc/getRtcToken')
          this.setBandwidthListeners()
          await this.$store.dispatch('calls/makeCall', {
            calledNumber: this.setPhoneStringForCall(this.phoneString),
            participantId: this.participantId,
            dialPad: this,
          })
          await this.setBandwidthConnection()
          await this.playOutboundCallAudio()
        }
      }
    },
    async finishActiveCall(rtcStream = null) {
      if (this.isEmergencyCall) {
        this.terminateCall()
      } else {
        await this.finishApiCall(rtcStream)
      }
    },
    async finishApiCall(rtcStream = null) {
      await this.stopOutboundCallAudio()
      if(rtcStream === null){
        rtcStream = this.callRtcStream
      }
      this.stateReady()
      this.$nextTick(() => {
        this.bus.$emit('finishTimer')
      })
      if(this.callId != null || this.incomingCallId != null){
        await this.hangupActiveCall(this)
      }
      const remoteVideoComponent = this.$refs['videoDev']
      if (remoteVideoComponent) {
        remoteVideoComponent.srcObject = undefined
      }
      await this.bandwidthRtc.unpublish(rtcStream)
      this.bandwidthRtc.disconnect()
      this.setWebRtcToken(null)
      if (this.$route.path !== '/call') {
        await this.$router.push({ name: 'call' })
      }
    },
    async finishEmergencyCall() {
      this.stateReady()
      this.$nextTick(() => {
        this.bus.$emit('finishTimer')
      })
    },
    async finishCallOnHold() {
      this.stateReady()
      this.$nextTick(() => {
        this.bus.$emit('finishTimer')
      })
      await this.hangup(this.callOnHold?.id)
      this.setCallOnHold({})
    },
    setBandwidthListeners() {
      this.bandwidthRtc.onStreamAvailable((rtcStream) => {
        this.stopOutboundCallAudio()
        this.setCallRtcStream(rtcStream.endpointId)
        const remoteVideoComponent = this.$refs['videoDev']
        remoteVideoComponent.srcObject = rtcStream.mediaStream
        this.stateActive()
        this.$nextTick(() => {
          this.bus.$emit('startTimer')
        })
      })
      this.bandwidthRtc.onStreamUnavailable((rtcStream) => {
        this.stopOutboundCallAudio()
        let  holdTimeInSeconds = Math.abs((new Date().getTime() - this.callOnHold.timeStamp) / 1000)
        if (this.callOnHold.rtcStream !== rtcStream ){ // Finish active call
          this.finishActiveCall(rtcStream)
        } else if (this.callOnHold.rtcStream === rtcStream && holdTimeInSeconds > 3) { // Finish hold call
          this.endCallOnHold()
        }
      })
    },
    async setBandwidthConnection(){
      await this.bandwidthRtc.connect({ deviceToken: this.rtcToken })
      await this.bandwidthRtc.publish({
        audio: true,
        video: false,
      })
    },
    muteCurrentCall() {
      this.callMuted = true
      this.bandwidthRtc.setMicEnabled(false)
    },
    unmuteCurrentCall() {
      this.callMuted = false
      this.bandwidthRtc.setMicEnabled(true)
    },
    async holdCurrentCall() {
      const tempPhoneString = this.phoneString
      this.$nextTick(() => {
        let holdCall = {
          id: this.callId,
          rtcStream: this.callRtcStream,
          phoneString: tempPhoneString,
          timeStamp: Date.now(),
        }
        this.setCallOnHold(holdCall)
      })
      this.stateReady()
      await this.holdCall(this.callId)
    },
    async resumeCall() {
      this.phoneString = this.callOnHold.phoneString
      await this.resumeCallOnHold(this.callId)
      this.stateActive()
      this.setCallOnHold({})
    },
    async endCallOnHold() {
      this.stateReady()
      this.$nextTick(() => {
        this.bus.$emit('finishTimer')
      })
      await this.hangupCallOnHold(this.callOnHold?.id)
    },
    async playOutboundCallAudio() {
      await this.$refs.outboundCallAudio.play()
      this.callStatus = 'Calling'
    },
    async stopOutboundCallAudio() {
      const audio = this.$refs.outboundCallAudio
      await audio.pause()
      audio.currentTime = 0
      this.callStatus = ''
    },
    stateHold() {
      this.phoneString = ''
    },
    stateActive() {
      this.activeCall = true
    },
    stateReady() {
      this.activeCall = false
      this.validPhoneNumber = false
      this.phoneString = ''
      while (this.getDialerDialed != '') {
        this.dialBackspace()
      }
    },
  },
}
</script>

<style lang="scss" scoped>

$lightGray: #525c66;
$green: #3DE066;
$red: #FA4A5D;

@-webkit-keyframes pulse-primary {
  0% {
    box-shadow: 0 0 0 0 rgba($primary, .6);
  }
  75% {
    box-shadow: 0 0 3px 8px rgba($primary, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($primary, 0);
  }
}

@keyframes dots {
  0%   { content: ''; }
  25%  { content: '.'; }
  50%  { content: '..'; }
  75%  { content: '...'; }
  100% { content: ''; }
}

.pad{
  width: 400px;
  height: 700px;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  animation: showPad 1s ease forwards 1;
  line-height: normal;
  letter-spacing: 1px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 lightgrey, 0 4px 12px 0 lightgrey;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  *,
  *::before,
  *::after {
    box-sizing: initial;
  }

  .phone-actions {
    display: flex;
    flex-direction: column;
    margin: 10px auto;

    &:nth-child(3n-1){
      margin: 10px calc(50% - 90px) !important;
    }
  }

  .dial-pad{
    .call-status{
      position: absolute;
      top: 5.5rem;
      left: 0;
      right: 0;
      margin-top: .5rem;
      text-align: center;
      &:after {
        position: absolute;
        display: inline-block;
        animation: dots steps(1,end) 1s infinite;
        content: '';
      }
    }
    .digits{
      display: flex;
      width: 60%;
      margin-left: 22%;
      margin-top: 3rem;
      border-right: .25rem;
      .dig{
        color: $lightGray;
        font-size: 30px;
        float: left;
        background-color: #efefef;
        text-align: center;
        width: 60px;
        height: 56px;
        border-radius: 100%;
        margin: 10px 0px;
        padding-top: 4px;
        font-weight: 700;
        cursor: pointer;
      }
    }
    .action-digits{
      display: flex;
      margin: auto;
      padding: 0 0.5rem;
      vertical-align: middle;
      .dig{
        margin: auto;
        &.callButton{
          background-color: $green;
          padding-top: 11px;
          height: 51px;
          color: white;
        }
        &.holdButton{
          background-color: $primary;
          padding-top: 11px;
          height: 51px;
          color: white;
        }
        &.cancelCall{
          padding-top: 11px;
          height: 51px;
          color: white;
          background-color: $red;
        }
        &.muteButton,&.unmuteButton{
          background-color: $primary;
          padding-top: 11px;
          height: 51px;
          color: white;
        }
        &.unmuteButton{
          animation: pulse-primary 1.5s infinite;
        }
      }
      .phone-actions > span{
        margin: .25rem auto;
        font-size: .85rem;
      }
    }
    .callButtonDisabled{
      opacity: 0.5 !important;
      cursor: default !important;
    }
  }
}
</style>
