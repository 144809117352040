var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "virtual-phone-call-log-edit-selection",
      attrs: { size: "lg", title: _vm.detailsTitle, "hide-footer": "" },
    },
    [
      _c(
        "div",
        [
          _c("b-card", { staticClass: "details-card mb-3" }, [
            _c("div", [
              _c("b", [_vm._v("Contact/Number:")]),
              _vm._v(" " + _vm._s(_vm.contactOrPhone) + "\n      "),
            ]),
            _c("div", [
              _c("b", [_vm._v("Date/Time:")]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.formatDateTime(_vm.virtualPhoneLog.started_at).date
                  ) +
                  " at " +
                  _vm._s(
                    _vm.formatDateTime(_vm.virtualPhoneLog.started_at).time
                  ) +
                  "\n      "
              ),
            ]),
            _c("div", [
              _c("b", [_vm._v("Call Type:")]),
              _vm._v(
                " " +
                  _vm._s(_vm.capFirstLetter(_vm.virtualPhoneLog.direction)) +
                  "\n      "
              ),
            ]),
            _vm.virtualPhoneLog.event_type === "completed"
              ? _c("div", [
                  _c("b", [_vm._v("Status:")]),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.capFirstLetter(_vm.virtualPhoneLog.event_type)
                      ) +
                      "\n      "
                  ),
                ])
              : _c("div", [
                  _c("b", [_vm._v("Status:")]),
                  _vm._v(" No answer\n      "),
                ]),
          ]),
          _c("div", { staticClass: "mb-2" }, [_c("hr")]),
          _c(
            "b-container",
            { staticClass: "contact-list m-0" },
            [
              _c(
                "b-card-group",
                { staticClass: "mb-3" },
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "pb-0 contact-card",
                      attrs: { disabled: _vm.isUnknownNumber },
                      on: { click: _vm.changeContact },
                    },
                    [_vm._v("\n          Set Contact Information\n        ")]
                  ),
                  _c(
                    "b-card",
                    {
                      staticClass: "pb-0 contact-card",
                      attrs: { disabled: _vm.isUnknownNumber },
                      on: { click: _vm.sendToSms },
                    },
                    [_vm._v("\n          Send SMS Message\n        ")]
                  ),
                  _c(
                    "b-card",
                    {
                      staticClass: "pb-0 contact-card",
                      attrs: { disabled: _vm.isUnknownNumber },
                      on: { click: _vm.callBack },
                    },
                    [_vm._v("\n          Call Back\n        ")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex flex-row m-auto btn-spacing" },
                [
                  _vm.isNumberBlocked
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "primary",
                            disabled: _vm.isUnknownNumber,
                          },
                          on: { click: _vm.unblockButtonClicked },
                        },
                        [_vm._v("\n          Unblock\n        ")]
                      )
                    : _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "danger",
                            disabled: _vm.isUnknownNumber,
                          },
                          on: { click: _vm.blockButtonClicked },
                        },
                        [_vm._v("\n          Block\n        ")]
                      ),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "danger" },
                      on: { click: _vm.deleteCallLog },
                    },
                    [_vm._v("\n          Delete\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("contact-modal", {
            ref: "contact-modal",
            attrs: {
              title: _vm.title,
              type: _vm.modalType,
              "phone-number": _vm.phoneNumber,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }