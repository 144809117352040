<template>
  <b-container class="phones-page">
    <virtual-phone-masthead
      title="Calling as"
      @loaded="loadedPhoneMasthead"
      @changed="setVirtualPhone"
    />
    <div v-if="virtualPhonesLoaded">
      <div v-if="arePhonesActive">
        <virtual-phone-call-log-filters ref="callLogFilters" :filter-options="filterOptions" :filter-label="'Filters'" @changed="setFiltersApplied" />
        <b-row v-if="loaded" class="d-flex align-content-start flex-wrap">
          <div v-if="callLogs.length" class="p-0 col-lg-4 log-border">
            <b-col class="p-0">
              <ul :style="{height: `${idealListHeight}px`, overflow:'scroll'}" class="list-group">
                <call-log-list-item v-for="log in callLogs" :key="log.initial_call_id" :virtual-phone-log="log" />
              </ul>
            </b-col>
          </div>
          <div v-else class="list-group no-logs-margin">
            <span>No call logs found for this number</span>
          </div>
          <div class="order-md-1 col-sm-12 col-md-8">
            <b-col>
              <dial-pad :destination-number="destinationNumber" :outgoing-call="outgoingCall" />
            </b-col>
          </div>
        </b-row>
        <ct-centered-spinner v-else height="260px" />
      </div>
      <div v-else>
        <p>There are currently no active phones.</p>
      </div>
    </div>
    <ct-centered-spinner v-else height="260px" />
  </b-container>
</template>

<script>

import DialPad from '@/components/DialPad/DialPad'
import CallLogListItem from '@/components/DialPad/CallLogListItem'
import VirtualPhoneMasthead from '@/components/VirtualPhones/VirtualPhoneMasthead'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
import { getDateFilter } from '@/common/modules/dates'
import VirtualPhoneCallLogFilters from '@/components/VirtualPhones/VirtualPhoneCallLogFilters'
import { mapGetters, mapActions } from 'vuex'
import { connectWithAccountId } from '@/common/modules/actionCable'

export default {
  name: 'Call',
  components: {
    CallLogListItem,
    DialPad,
    CtCenteredSpinner,
    VirtualPhoneMasthead,
    VirtualPhoneCallLogFilters,
  },
  props: {
    destinationNumber: String,
    outgoingCall: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      loaded: false,
      virtualPhoneId: null,
      virtualPhoneLogs: [],
      virtualPhonesLoaded: false,
      filterOptions: {
        dateRadioGroup: {
          filters: [
            getDateFilter('PAST_1_WEEK'),
            getDateFilter('PAST_1_MONTH'),
            getDateFilter('PAST_3_MONTHS'),
            getDateFilter('PAST_6_MONTHS'),
          ],
          custom: false,
        },
      },
    }
  },

  channels: {
    PhoneNotificationChannel: {
      async received (data) {
        if (data.message.event === 'call_ended') {
          await this.setCallLogs()
        }
      },
    },
  },

  async mounted () {
    if (!this.$cable._isReset) {
      await connectWithAccountId(this.$cable)
    }
    this.$cable.subscribe({
      channel: 'PhoneNotificationChannel',
    })
  },

  computed: {
    ...mapGetters('virtualPhones', ['selectedPhone', 'callLogs']),
    idealListHeight () {
      const offsets =
        60 // top nav
        + 89 // masthead
        + 40 // horizontal rule

      return window.innerHeight - offsets
    },
    arePhonesActive(){
      return this.selectedPhone
    },
  },

  methods: {
    ...mapActions('virtualPhones', ['getBlockedNumbers', 'commitCallLogs']),
    async setCallLogs(start_date, end_date) {
     await this.commitCallLogs({ start_date, end_date, virtualPhoneId: this.virtualPhoneId })
    },

    async setVirtualPhone() {
      this.virtualPhonesLoaded = true
      this.virtualPhoneId = this.selectedPhone?.id
      const virtualPhoneIdFromURL = this.$route.params.virtualPhoneId
      if (virtualPhoneIdFromURL && virtualPhoneIdFromURL !== this.virtualPhoneId) {
        await this.$router.push({ name: 'call', params: { virtualPhoneId: this.virtualPhoneId } })
      }
      if (this.$refs.callLogFilters) {
        this.$refs.callLogFilters.refreshFilters()
      }

      await this.getBlockedNumbers()
    },

    async setFiltersApplied(filters) {
      this.loaded = false
      await this.setCallLogs(filters.filters.start_date, filters.filters.end_date)
      this.loaded = true
    },
    async loadedPhoneMasthead() {
      await this.setVirtualPhone()
      await this.getBlockedNumbers()
    },
  },
}
</script>

<style>
.log-border {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
}

.col-md-4 {
  max-width: none;
}

.no-logs-margin {
  margin-left: 30px;
  margin-right: 5px;
}
</style>
